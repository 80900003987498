// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div style="width: 100%;">
    <!-- Add Endpoint Modal -->
    <a-modal
      :title="$t('label.add.cybercloud.endpoint')"
      @ok="submitAddEndpoint()"
      @cancel="closeAction"
      okText="OK"
      cancelText="Cancel"
      v-model:visible="openAddEndpointModal"
      :closable="true"
      centered>
      <a-form
        v-ctrl-enter="submitAddEndpoint"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitAddEndpoint"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <span>Bitte geben Sie einen Namen für den zu erstellenden Endpoint an.</span>
          <a-form-item
            ref="endpointName"
            name="endpointName"
            :label="'Name'">
            <a-input
              v-model:value="form.endpointName"
              style="margin-top: 5px;"
              :placeholder="'Enter a name for the endpoint'" />
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Show Auth-Token Modal -->
    <a-modal
      :title="$t('message.success.cybercloud.endpoint')"
      v-model:visible="showAuthTokenModal"
      :closable="false"
      centered>
      <p>{{ $t('message.success.cybercloud.endpoint') }}</p>
      <p>{{ $t('message.success.cybercloud.endpoint.authKey') }}</p>
      <p>{{ authKey }}</p>
      <template #footer>
        <a-button
          type="primary"
          @click="showAuthTokenModal=false">
          {{ 'OK' }}
        </a-button>
      </template>
    </a-modal>
    <!-- Start of Table /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <a-card>
      <div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
        <a-button id="addEndpointBtn" type="primary" shape="round" @click="openAddEndpointModal=true">{{ $t('label.add.cybercloud.endpoint') }}</a-button>
        <a-button id="downloadEndpoint" type="default" shape="round" style="margin-left: 10px;"><a href="http://dl.mitteldeutsche-it.de/CyberCloud/cyber-cloud-install.zip"><DownloadOutlined style="margin-right: 5px;"/>{{ $t('label.download.cybercloud.endpoint.zip') }}</a></a-button>
      </div>
      <list-view
        :columns="columns"
        :items="items"
        @refresh="fetchData">
      </list-view>
    </a-card>
  </div>
</template>

<script>
import ListView from '@/components/view/ListView'
import AutogenView from '@/views/AutogenView.vue'
import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'CyberCloudEndpoint',
  components: {
    ListView,
    AutogenView
  },
  data () {
    return {
      regex: /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/,
      openAddEndpointModal: false,
      showAuthTokenModal: false,
      loggedInAccount: this.$store.getters.userInfo.accountid,
      loading: false,
      endpointData: [
        /* {
          name: 'endpoint1',
          auth_key: '123456',
          resourceType: 'endpoint',
          status: 'installed',
          created_at: '01-04-22',
          hostname: 'end1Hostname',
          cloud_account_id: '88888888',
          uuid: '10dce79c-b66a-4d8a-bdc8-02b61e3abea2',
          hardware_id: 'hardware123'
        },
        {
          name: 'endpoint2',
          auth_key: '7891011',
          resourceType: 'endpoint',
          status: 'to_install',
          created_at: '09-08-22',
          deleted_at: null,
          hostname: 'end2Hostname',
          cloud_account_id: '88888888',
          uuid: '10dce79c-b66a-4d8a-bdc8-02b61e3abea2',
          hardware_id: 'hardware456'
        } */
      ],
      acronisVms: [
        /*  {
          status: 'installed',
          created_at: '07-07-22',
          uuid: '555a493b-61a4-4507-baa7-96bc3a8c34f3',
          resourceType: 'vm',
          cloud_account_id: '88888888',
          hardware_id: 'vmUUID132312312'
        },
        {
          status: 'Running',
          created_at: '12-12-22',
          deleted_at: 'test',
          resourceType: 'vm',
          uuid: '10dce79c-b66a-4d8a-bdc8-02b61e3abea2',
          cloud_account_id: '88888888',
          hardware_id: 'vmUUID78878787'
        } */
      ],
      authKey: undefined,
      items: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'name'
          },
          sorter: function (a, b) { return a.name.localeCompare(b.name) },
          onFilter: (value, record) =>
            record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              /* setTimeout(() => {
                document.getElementById('searchField').focus()
              }, 0) */
            }
          }
        },
        {
          title: 'Authentication Key',
          dataIndex: 'auth_key'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' }
        },
        {
          title: 'Created at',
          dataIndex: 'created_at',
          sorter: function (a, b) { return new Date(a.created_at) - new Date(a.created_at) }
        },
        {
          title: 'Hostname',
          dataIndex: 'hostname'
        },
        {
          title: 'Hardware Id',
          dataIndex: 'hardware_id'
        },
        {
          title: this.$t('label.type'),
          dataIndex: 'resourceType'
        },
        {
          title: this.$t('label.options'),
          dataIndex: 'deleted_at',
          slots: { customRender: 'deleted_at' },
          align: 'center'
        }
      ]
    }
  },
  provide: function () {
    return {
      parentFetchData: this.fetchData,
      parentToggleLoading: () => { this.loading = !this.loading }
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        endpointName: undefined
      })
      this.rules = reactive({
        endpointName: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateEndpointName, trigger: 'change' }
        ]
      })
    },
    async validateEndpointName (rule, value) {
      const errorMessage = 'Der Endpoint Name darf maximal 64 Zeichen enthalten!'
      if (value.length < 65) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async fetchData () {
      await this.fetchEndpoints()
      await this.fetchAcronisVms()
      this.combineEndpointAndVms()
    },
    async fetchEndpoints () {
      var getEndpointParams = new FormData()
      getEndpointParams.append('account_id', this.loggedInAccount)
      api2.getAcronisEndpoint(getEndpointParams).then(response => {
        if (response.data && response.data !== null && response.data !== 'Error') {
          this.endpointData = response.data
        } else {
          console.log('No endpoints available!')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchAcronisVms () {
      var getVmParams = new FormData()
      getVmParams.append('account_id', this.loggedInAccount)
      await api2.getAcronisVMs(getVmParams).then(response => {
        if (response.data && response.data !== null && response.data !== 'Error') {
          this.acronisVms = response.data
        } else {
          this.acronisVms = []
          console.log('No VMs available!')
        }
      }).catch(error => {
        console.log(error)
      })
      await api('listVirtualMachines', { accountid: this.loggedInAccount, listall: true }).then(json => {
        var vms = json.listvirtualmachinesresponse.virtualmachine
        this.acronisVms.forEach(vm => {
          if (vms.filter(x => x.id === vm.uuid)[0] !== undefined) {
            vm.name = vms.filter(x => x.id === vm.uuid)[0].name
          }
        })
      })
    },
    combineEndpointAndVms () {
      var itemData = []
      itemData.push(...this.acronisVms)
      itemData.push(...this.endpointData)
      this.items = itemData
    },
    closeAction () {
      this.openAddEndpointModal = false
    },
    submitAddEndpoint () {
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)

        var params = new FormData()
        params.append('name', values.endpointName)
        params.append('account_id', this.loggedInAccount)
        await api2.addAcronisEndpoint(params).then(response => {
          if (response.data && this.regex.test(response.data)) {
            this.authKey = response.data
          } else {
            console.log('Error adding Endpoint')
          }
          if (this.authKey !== undefined) {
            this.showAuthTokenModal = true
            this.closeAction()
          }
        }).catch(error => {
          this.$notifyError(error)
          this.closeAction()
        }).finally(() => {
          this.fetchData()
        })
      })
    }
  }
}
</script>
